import axios from '@/libs/axios'

class DataService {
  getAll(params, page) {
    return axios.get(`/hospitals?page=${page}&${params}`);
  }
  get(id) {
    return axios.get(`/hospitals/${id}`);
  }
  create(data) {
    return axios.post("/hospitals", data);
  }
  update(id, data) {
    return axios.put(`/hospitals/${id}`, data);
  }
}
export default new DataService();