<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{headerType}} {{pageTitle}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="brnadForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="initValues"
        >

          <!--  Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.name">{{ allerrors.name[0] }}</span>
            </b-form-group>
          </validation-provider>

           <!--  Address -->
           <validation-provider
           #default="validationContext"
           name="Address"
           rules="required"
         >
           <b-form-group
             label="Address"
             label-for="address"
           >
             <b-form-input
               id="address"
               v-model="address"
               autofocus
               :state="getValidationState(validationContext)"
               trim
               placeholder="Enter Address"
             />

             <b-form-invalid-feedback>
               {{ validationContext.errors[0] }}
             </b-form-invalid-feedback>
             <span class="text-danger text-sm" v-if="allerrors.address">{{ allerrors.address[0] }}</span>
           </b-form-group>
         </validation-provider>


          <!--  Region -->
          <validation-provider
            #default="validationContext"
            name="Select Region"
            rules="required"
          >
            <b-form-group
              label="Select Region"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
               <v-select
                  id="type"
                  v-model="region_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="regions"
                  :reduce="types => types.id"
                  
                />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.region_id">{{ allerrors.region_id[0] }}</span>
            </b-form-group>
          </validation-provider>


          <!--  Type -->
          <validation-provider
            #default="validationContext"
            name="Select Type"
            rules="required"
          >
            <b-form-group
              label="Select Type"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
               <v-select
                  id="type"
                  v-model="type_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="hospitalType"
                  :reduce="types => types.id"
                  
                />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.type_id">{{ allerrors.type_id[0] }}</span>
            </b-form-group>
          </validation-provider>

          
          <Map v-if="isAddNewUserSidebarActive" @changeCoordinate="changeCoordinate" :coordinate="coordinate"/>



          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!loading"
            >
              {{ headerType }} 
            </b-button>
            <!-- spinner -->
            <b-button
              v-if="loading"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner small />
              Loading...
            </b-button>
            <!-- end spinner -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormFile, BCardText, BMedia, BAvatar, BSpinner,  BRow, BCol
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onMounted, ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { watch } from '@vue/composition-api'
import DataService from './data/services'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ThumbsDownIcon } from 'vue-feather-icons'
import Map from './map.vue'
export default {
  
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BCardText,
    BMedia,
    BAvatar,
    BSpinner,
    vSelect,
    BRow, 
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    Map,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    dataSlider: {
      type: Object,
      default: () => {},
    },
    companyType: {
      type: [Array, Object],
      required: true
    },
    pageTitle: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      required,
      alphaNum,
      email,
      allerrors: [],
      headerType: 'Add',
      name:'',
      address: '',
      region_id: '',
      type_id: '',
      coordinate: '13.455455454,33.222112332',
      id: '',
      nextTodoId: 2,
    }
  },
  watch: {
    isAddNewUserSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.dataSlider).length === 0) {
        this.headerType = 'Add'
        this.initValues()
      }else {        
        this.id = this.dataSlider.id
        this.name = this.dataSlider.name
        this.region_id = this.dataSlider.region_id
        this.type_id = this.dataSlider.type_id
        this.address = this.dataSlider.address
        this.coordinate = this.dataSlider.coordinate
        this.headerType = 'Edit'
      }
    }
  },
  methods: {
    initValues() {
      if(this.dataSlider.id) return
        this.id = null
        this.name = null
        this.region_id = null
        this.type_id = null
        this.address = null
        this.coordinate = null
        this.allerrors = {}
    },
    onSubmit(){
      if(!this.id){
        this.sendData()
      }else{
        this.editData();
      }
    },
    prepareData(){
      return {
        name: this.name,
        region_id: this.region_id,
        type_id: this.type_id,
        address: this.address,
        coordinate: this.coordinate
      }
    },
    changeCoordinate(val){ 
      this.coordinate = val
    },
    sendData(){
      this.loading = true;

        DataService.create(this.prepareData())
        .then(response => {
          this.loading = false
          let responseCode = response.data.responseCode
          if(responseCode == 100){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.responseMessage,
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.responseDescription
              },
            })
            this.$parent.refreshData(response.data.data);
            // this.$emit('refreshData('+response.data.data+')');
            
            this.$emit('update:is-add-new-user-sidebar-active', false)
          }else if(responseCode === 101){
            this.allerrors = response.data.errors
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
          else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }
          let message = Error
          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },
    editData(){
      this.loading = true;

        DataService.update(this.id, this.prepareData())
        .then(response => {
          this.loading = false
          let responseCode = response.data.responseCode
          if(responseCode == 100){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.responseMessage,
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.responseDescription
              },
            })
            console.log(response.data.data);
            this.$parent.refreshNewData(response.data.data);
            // this.$emit('refreshData('+response.data.data+')');
            
            this.$emit('update:is-add-new-user-sidebar-active', false)
          }else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }
          let message = Error
          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    repareType(type){
      let list=[];
      type.forEach(function(value, key) {
        list.push(value.type);
      });
      return list
    }
    
  },
  computed: {
    regions (){
      return this.$store.getters['admin/GetRegions'] 
    },
    hospitalType (){
      return this.$store.getters['admin/GetHospitalType'] 
    }
  },
  setup() {

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()
    return {
      // userData,
      // onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      //  ? Demo - Update Image on click of update button

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>